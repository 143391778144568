import React from 'react';
import styles from './Education.module.css';
import Descriptor from '../../../Components/Descriptor';
import Image from '../../../Components/Image';
import { EDUCATIONS_URL } from '../../../constants';
import TESTIMAGEjpg from '../../../assets/Education/education-image.jpg';
import EDUCATIONLOGOpng from '../../../assets/Education/ourbrands-logo1.png';
import ELLIPSEpng from '../../../assets/Education/Ellipse.png';
import TESTIMAGEwebp from '../../../assets/Education/education-image.webp';
import EDUCATIONLOGOwebp from '../../../assets/Education/ourbrands-logo1.webp';
import ELLIPSEwebp from '../../../assets/Education/Ellipse.webp';

const description =
  'We are a young education company on a mission to be the best . We primarily deal with 5 technologies - Machine Learning, Deep Learning, Computer Vision, Image Processing and React JS and has been focusing on these technologies for the past 3 years.Our main focus is to help our students get accustomed to the corporate world. We ensure our students are mentored and placed in some of the top working startups and to single out right person for right technologies for these startups that has the potential to become the next unicorn. We have mentored more than 2000 students and have never really altered with any other technologies. We have our own employer network that consists of 100+ top working startups in the 5 technologies across India which have got million dollar fundings. We also organise workshops, seminars and training programs for both schools and colleges. We help our Students to learn by assisting them to bring the best out of them. The top performers of the program are hired as interns to work on our different projects and products.We provide 150-300+ hours mentorship program, where the undergrads, select one of Machine Learning, Front end Development, Back End Development, Competitive Coding, ERP solutions in Java, UI / UX Design and Development, etc. and then go from weekly 10-40 hours of mentorship program depending upon the batch they are in. Taking a modular training approach, we enhance their skills by getting their hands dirty on more than 15 live projects in each course.A weekly special guidance sessions from our collaborator colleagues working in top companies such as Amazon, Facebook, Samsung, Adobe, etc. is also conducted. we assist them with guaranteed internships & jobs across companies in India. ';
const Education = () => {
  return (
    <div className={styles.parentContainer}>
      <div className={styles.childCotainer}>
        <div className={styles.ellipseBackGround}>
          <Image
            alt="Education"
            src={ELLIPSEpng}
            webpImage={ELLIPSEwebp}
            className={styles.ellipse}
          />
        </div>
        <div className={styles.imageContainer}>
          <div className={styles.imageBox}>
            <Image
              webpImage={TESTIMAGEwebp}
              className={styles.image}
              src={TESTIMAGEjpg}
            />
          </div>
        </div>
        <div className={styles.logoTextContainer}>
          <Descriptor
            description={description}
            descriptorAlignment={styles.educationTextAlign}
            webpImage={EDUCATIONLOGOwebp}
            image={EDUCATIONLOGOpng}
            alt="EDUCATION BRAND"
            knowMoreText="LEARN MORE"
            descriptionStyle={styles.descriptorStyle}
            maxDescriptionCharacters={326}
            URL={EDUCATIONS_URL}
          />
        </div>
      </div>
    </div>
  );
};
export default Education;
